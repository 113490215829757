(function(){
	var bannerAtivo = $('.count-up .ativo');

	var totalDosBanners = $('.count-up .todos')

	var variavelQuePegaNumeroDoTotalDosCarousel = parseInt($('#banner .item').length);

	$('#banner').on('slid.bs.carousel', function(){
		var valorAtivoDoBanner = parseInt($('#banner .item.active').attr('data-number'));

		if(valorAtivoDoBanner < 10){
			valorAtivoDoBanner = '0' + valorAtivoDoBanner;
		}else{
			valorAtivoDoBanner = valorAtivoDoBanner;
		}

		bannerAtivo.text(valorAtivoDoBanner);

	});

	if(variavelQuePegaNumeroDoTotalDosCarousel < 10){
		totalDosBanners.text('/0' + variavelQuePegaNumeroDoTotalDosCarousel);
	}else{
		totalDosBanners.text('' + variavelQuePegaNumeroDoTotalDosCarousel);
	}

})();