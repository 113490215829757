function joazin(id, opcoes){
    if($(id).children().length > 1){
        $(id).owlCarousel(opcoes)
    }else{
        $(id).addClass('owl-loaded');
    }
}

joazin('#parceiros-carousel',{
    loop:true,
    autoplay:false,
    autoplayTimeout:4000,
    nav:true,
    navText: [
        '<span class="circle" title="Item Anterior"><i class="fa fa-chevron-left"></i></span>',
        '<span class="circle" title="Próximo Item"><i class="fa fa-chevron-right"></i></span>'
    ],
    responsive:{
        0:{
            items:1,
        },
        577:{
            items:2,
        },
        768:{
            items:3,
            margin:5,
        },
        992:{
            items:4,
            margin:5,
        },
        1170:{
            items:4,
            margin:10,
        }
    }
})